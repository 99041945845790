<template>
  <div>
    <v-row dense class="mb-4">
      <v-col cols="12" sm="5" lg="3" class="pl-0 mr-2">
        <v-card>
          <v-card-text class="pt-0">
            <v-layout justify-center align-center>
              <p
                v-if="!loadingCreditsAmount"
                class="font-weight-bold primary--text text-h1 mt-4 mb-0"
              >
                {{ getAmountCredits || 0 }}
              </p>
              <preloader v-else></preloader>
            </v-layout>
            <v-layout justify-center align-center>
              <p class="font-weight-bold text-h5 mb-0">
                {{ $t("Available credits") }}
              </p>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" lg="3">
        <v-layout align-end fill-height>
          <v-btn color="primary" @click="goToCredits()">
            {{ $t("Order credits") }}
            <v-icon right> mdi-arrow-right </v-icon>
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>

    <v-card flat>
      <v-card-title class="px-0">{{ $t("History") }}</v-card-title>
      <v-card-text class="px-0">
        <v-row dense v-if="!loadingCreditsHistory">
          <template v-if="creditsHistory.length">
            <v-col
              cols="12"
              v-for="(credit, index) in getCreditsHistory"
              :key="index"
              class="pl-0"
            >
              <v-card>
                <v-card-text>
                  <v-row no-gutters align="center">
                    <v-col cols="4" v-if="credit.Amount > 0">
                      <v-layout align-center>
                        <v-icon class="mr-2" color="success">mdi-plus</v-icon>
                        <p class="mb-0">
                          {{ (credit.Amount + " ").replace("-", "") + $t("credits") }}
                        </p>
                      </v-layout>
                    </v-col>
                    <v-col cols="4" v-else>
                      <v-layout align-center>
                        <v-icon class="mr-2" color="error">mdi-minus</v-icon>
                        <p class="mb-0">
                          {{ (credit.Amount + " ").replace("-", "") + $t("credits") }}
                        </p>
                      </v-layout>
                    </v-col>
                    <v-col cols="4"
                      ><v-layout justify-center>
                        {{ changeTimeToReadeble(credit.Date) }}
                      </v-layout>
                    </v-col>
                    <v-col cols="4">
                      <v-layout justify-end>
                        {{ credit.Description }}
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
          <p v-else>{{ $t("No data available") }}</p>
        </v-row>
        <preloader v-else></preloader>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import helpers from "../../helpers";
import { mapGetters } from "vuex";
import request from "../../request";
import Preloader from "../shared/Preloader.vue";

export default {
  components: { Preloader },
  data() {
    return {
      creditsHistory: [],
      buyCreditsDialog: false,
      dialogCredits: null,
      webId: null,
      creditsAmount: null,
      loadingCreditsAmount: true,
      loadingCreditsHistory: true,
    };
  },
  created() {
    request.get("/api/credits-total-amount", null, (res) => {
      this.loadingCreditsAmount = false;
      if (res.success) {
        this.creditsAmount = res.item;
      }
    });
    request.get("/api/credits", null, (res) => {
      this.loadingCreditsHistory = false;
      this.creditsHistory = res;
    });
    for (const key in this.selectedWebsite.Pages) {
      if (
        this.selectedWebsite.Pages[key]._id.$oid == "6389f92ea65d4e37b12e7d4b"
      ) {
        this.webId = this.selectedWebsite.Pages[key];
      }
    }
  },
  computed: {
    ...mapGetters({
      websites: "allWebsites",
      selectedWebsite: "selectedWebsite",
    }),
    getCreditsHistory() {
      return this.creditsHistory;
    },
    getAmountCredits() {
      return this.creditsAmount;
    },
  },
  methods: {
    goToCredits() {
      this.$router.push({
        name: "editPage",
        params: { id: this.webId._id.$oid },
      });

      this.$router.go(1);
    },
    changeTimeToReadeble(date) {
      return helpers.changeTimeToReadeble(date, false);
    },
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>